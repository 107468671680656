<script setup lang="ts">
  import { NotificationGroup, Notification, type TNotificationType } from "@/plugins/notiwind";

  const iconType = (value: TNotificationType): string => {
    if (value === "success") return "check";
    if (value === "warning") return "exclamation";
    if (value === "error") return "xmark";
    return "info";
  };
</script>

<template>
  <NotificationGroup>
    <div class="fixed right-0 top-0 z-40 flex items-start justify-end px-2">
      <div class="w-full max-w-md">
        <Notification
          v-slot="{ notifications, close, hovering }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="relative mx-auto mt-2 flex w-full max-w-sm overflow-hidden rounded border bg-white shadow-md"
            role="alert"
            @mouseover="hovering(notification.id, true)"
            @mouseleave="hovering(notification.id, false)"
          >
            <div
              class="flex w-8 shrink-0 items-center justify-center"
              :class="{
                'bg-gray-500': !notification.type,
                'bg-success-500': notification.type === 'success',
                'bg-info-500': notification.type === 'info',
                'bg-danger-500': notification.type === 'error',
                'bg-warning-500': notification.type === 'warning',
              }"
            >
              <font-icon
                class="h-5 w-5 fill-current text-white"
                :icon="['fas', iconType(notification.type)]"
              />
            </div>
            <div class="relative -mx-3 rounded px-4 py-2">
              <div class="mx-3">
                <div class="pr-5">
                  <span
                    class="font-semibold"
                    :class="{
                      'text-gray-500': !notification.type,
                      'text-success-500': notification.type === 'success',
                      'text-info-500': notification.type === 'info',
                      'text-danger-500': notification.type === 'error',
                      'text-warning-500': notification.type === 'warning',
                    }"
                  >
                    {{ notification.title }}
                  </span>
                  <p class="whitespace-pre-wrap text-sm">{{ notification.text }}</p>
                </div>
                <button class="absolute right-0 top-0 px-5 py-1" @click="close(notification.id)">
                  <font-icon
                    icon="fa-solid fa-xmark"
                    class="h-4 w-4 cursor-pointer text-gray-500 hover:text-gray-800"
                  />
                </button>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>
