import { ApiClient } from "@/composable/useApi";

const headers_json = {
  "Content-Type": "application/json",
};

class ContractApiClient extends ApiClient {
  public async getProducts() {
    const response = await this.axiosInstance.get(`${this.apiPath}/products/`);
    return response.data;
  }

  public async getContractPredefinedData() {
    const response = await this.axiosInstance.get(`${this.apiPath}/misc/predefined-data`);
    return response.data;
  }

  public async getConditions(productType: string, contractDate: string, isReinvest = false) {
    const response = await this.axiosInstance.get(`${this.apiPath}/products/conditions`, {
      params: { productType, contractDate, isReinvest },
    });
    return response.data;
  }

  public async getContract(contractId: string) {
    const response = await this.axiosInstance.get(`${this.apiPath}/contract/${contractId}`);
    return response.data;
  }

  public async create(productType: string) {
    const response = await this.axiosInstance.post(
      `${this.apiPath}/contract/`,
      JSON.stringify({ productType: productType }),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }

  public async calculation(orderNumber: string, productType: string, contractData: object) {
    const body = {
      productType: productType,
      data: contractData,
    };

    const response = await this.axiosInstance.post(
      `${this.apiPath}/contract/${orderNumber}/calculate`,
      JSON.stringify(body),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }

  public async save(contractId: string, contractData: object) {
    const response = await this.axiosInstance.put(
      `${this.apiPath}/contract/${contractId}`,
      JSON.stringify(contractData),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }

  public async registration(contractId: string, contractData: object) {
    const response = await this.axiosInstance.post(
      `${this.apiPath}/contract/${contractId}/registration`,
      JSON.stringify(contractData),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }

  public async confirm(contractId: string, contractData: object) {
    const response = await this.axiosInstance.post(
      `${this.apiPath}/contract/${contractId}/confirm`,
      JSON.stringify(contractData),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }

  public async cancelRegistration(contractId: string, dateModified: string) {
    const response = await this.axiosInstance.post(
      `${this.apiPath}/contract/${contractId}/cancel`,
      {
        dateModified,
      },
    );
    return response.data;
  }

  public async underwriting(contractId: string, contractData: object) {
    const response = await this.axiosInstance.post(
      `${this.apiPath}/contract/${contractId}/underwriting`,
      JSON.stringify(contractData),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }

  public async getUnderwritingMessages(contractId: string) {
    const response = await this.axiosInstance.get(
      `${this.apiPath}/underwriting/${contractId}/messages`,
    );
    return response.data;
  }

  public async sendUnderwritingMessage(contractId: string, text: string) {
    const body = {
      text,
    };

    const response = await this.axiosInstance.post(
      `${this.apiPath}/underwriting/${contractId}/messages`,
      JSON.stringify(body),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }

  public async getResponsibleUsers() {
    const response = await this.axiosInstance.get(`${this.apiPath}/misc/responsible-users`);
    return response.data;
  }

  public async getVerificationStatuses(contractId: string) {
    const response = await this.axiosInstance.get(`${this.apiPath}/verification/${contractId}`);
    return response.data;
  }

  public async sendVerificationSmsCode(contractId: string) {
    const body = {
      action: "sendVerificationCode",
    };

    const response = await this.axiosInstance.post(
      `${this.apiPath}/verification/${contractId}/phone/send`,
      JSON.stringify(body),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }

  public async confirmVerification(contractId: string, smsCode: string) {
    const body = {
      action: "confirmVerificationCode",
      smsCode: smsCode,
    };

    const response = await this.axiosInstance.post(
      `${this.apiPath}/verification/${contractId}/phone/confirm`,
      JSON.stringify(body),
      {
        headers: headers_json,
      },
    );
    return response.data;
  }
}

const contractApiClient = new ContractApiClient();

export default contractApiClient;
