import { notify, type TNotificationSchema } from "@/plugins/notiwind";
import { v4 as uuidv4 } from "uuid";

import useAppStore from "@/modules/app/store";

const notification = (options: TNotificationSchema, timeoutSec?: number | undefined) => {
  const appStore = useAppStore();

  notify({ ...options }, timeoutSec ? timeoutSec * 1000 : 5000);

  appStore.notifications.unshift({ ...options, id: uuidv4(), timestamp: Date.now() });
};

export default notification;
