<script setup lang="ts">
  import { ref, watch, computed } from "vue";

  import { useAuthStore } from "@/modules/auth";
  import useAppStore from "../store";

  const authStore = useAuthStore();
  const appStore = useAppStore();

  const isOpen = ref(false);
  const needChange = ref(false);
  const textMsg = computed(() => {
    return needChange.value
      ? "Внимание! Вам необходимо самостоятельно оформить и направить вашему руководителю заявку на изменение точки продаж."
      : `Текущая точка продаж: "${authStore.user?.salesPoint?.name}".\nИзменить точку продаж?`;
  });

  watch(
    () =>
      authStore.userName &&
      authStore.user.settings?.needSalesPointNotify &&
      !appStore.modalSalesPointShown,
    (value) => {
      if (value) {
        isOpen.value = true;
        appStore.modalSalesPointShown = true;
      }
    },
    { immediate: true },
  );
</script>

<template>
  <el-modal id="modalSalesPointNotify" :open="isOpen" noclose @close="isOpen = false">
    <span class="whitespace-pre-wrap text-sm">
      {{ textMsg }}
    </span>
    <template #action>
      <template v-if="!needChange">
        <el-button
          class="btn inline-flex w-full justify-center sm:ml-2 sm:w-auto"
          @click="needChange = true"
        >
          Да
        </el-button>
        <el-button
          class="btn-primary mb-2 inline-flex w-full justify-center sm:mb-0 sm:ml-2 sm:w-auto"
          @click="isOpen = false"
        >
          Нет
        </el-button>
      </template>
      <template v-else>
        <el-button
          class="btn mb-2 inline-flex w-full justify-center sm:mb-0 sm:ml-2 sm:w-auto"
          @click="authStore.logout"
        >
          Ок
        </el-button>
      </template>
    </template>
  </el-modal>
</template>
