<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "LayoutFull",
  });
</script>

<template>
  <router-view />
</template>
