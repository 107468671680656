<script setup lang="ts">
  const textInputOptions = {
    format: ["dd.MM.yyyy", "ddMMyyyy"],
    openMenu: false,
  };
</script>

<template>
  <VueDatePicker
    text-input
    auto-apply
    keep-action-row
    :enable-time-picker="false"
    :action-row="{ showNow: true, showSelect: false, showCancel: false, showPreview: false }"
    now-button-label="Сегодня"
    locale="ru-RU"
    model-type="yyyy-MM-dd'T'HH:mm:ss"
    format="dd.MM.yyyy"
    :text-input-options="textInputOptions"
  />
</template>
