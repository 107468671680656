import axios, { AxiosInstance } from "axios";
import { v4 as uuidv4 } from "uuid";

import { apiUrl, apiPath } from "@/config";

let api: AxiosInstance;

export const headers_json = {
  "Content-Type": "application/json",
};

export function createApi(): AxiosInstance {
  api = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  });

  api.interceptors.request.use((config) => {
    const newConfig = config;
    newConfig.headers["X-Request-Id"] = uuidv4();
    return newConfig;
  });

  return api;
}

export function useApi(): AxiosInstance {
  if (!api) {
    createApi();
  }
  return api;
}

export class ApiClient {
  readonly axiosInstance: AxiosInstance;
  readonly apiPath: string;

  constructor() {
    this.axiosInstance = useApi();
    this.apiPath = apiPath;
  }
}
