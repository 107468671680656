import { createRouter, createWebHistory } from "vue-router";
import { RouteLocationNormalized } from "vue-router";
import { useStorage } from "@vueuse/core";

import { loggedKey } from "@/config";
import { routes as authRoutes } from "@/modules/auth";
import { routes as dataTablesRoutes } from "@/modules/dataTables";
import { routes as contractRoutes } from "@/modules/contract";
import { routes as contractSupportRoutes } from "@/modules/contractSupport";

import PageHome from "@/views/PageHome.vue";
import PageError404 from "@/views/pages/PageError404.vue";

const routes = [
  ...authRoutes,
  {
    path: "",
    name: "home",
    component: PageHome,
    meta: {
      layout: "Horizontal",
      requiresAuth: true,
    },
  },
  ...dataTablesRoutes,
  ...contractRoutes,
  ...contractSupportRoutes,
  {
    path: "/error-404",
    name: "error-404",
    component: PageError404,
    meta: {
      layout: "Full",
      requiresAuth: false,
    },
  },
  { path: "/:pathMatch(.*)*", component: PageError404 },
];

const router = createRouter({ history: createWebHistory(), routes });

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const isLoggedIn = String(useStorage(loggedKey, "false").value).toLowerCase() === "true";

  if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
    return { name: "auth-login", query: { redirect: to.fullPath } };
  } else if (
    // Если пользователь авторизован и открывает страницу входа
    // Или напрямую открывается страница с договором
    (isLoggedIn && to.name === "auth-login") ||
    (to.name?.toString().startsWith("contract-") && !from.name)
  ) {
    return { name: "home" };
  }
});

export default router;
