import { App } from "vue";
import { Router } from "vue-router";
import * as Sentry from "@sentry/vue";

import { sentryDsn, isDevEnv, environment } from "@/config";

export const regPlugin = (app: App, router: Router) => {
  Sentry.init({
    app,
    dsn: sentryDsn,
    environment: environment,
    integrations: [Sentry.browserTracingIntegration({ router })],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost"],
    enabled: Boolean(sentryDsn),
  });
};
