import type { App } from "vue";
import Notifications, {
  createNotifier,
  NotificationGroup,
  defineNotificationComponent,
} from "notiwind";

export const regPlugin = (app: App) => {
  app.use(Notifications);
};

export type TNotificationType = "success" | "info" | "warning" | "error";

export type TNotificationSchema = {
  type: TNotificationType;
  text: string;
  id?: string;
  title?: string;
};

export const notify = createNotifier<TNotificationSchema>();
export const Notification = defineNotificationComponent<TNotificationSchema>();
export { NotificationGroup };
