<script setup lang="ts">
  import { MenuItem } from "@headlessui/vue";

  import { asDateTime } from "@/helpers/formatters";
  import useAppStore from "../store";

  const appStore = useAppStore();

  const handleDelete = (id: string) => {
    appStore.notifications = appStore.notifications.filter((item) => item.id !== id);
  };
</script>

<template>
  <el-dropdown size="large">
    <template #button>
      <div class="flex items-center text-gray-300">
        <font-icon
          class="rounded-full p-1 hover:bg-primary-600 hover:text-white"
          icon="fa-regular fa-bell"
        />
      </div>
    </template>
    <template #content>
      <div class="text-gray-900">
        <div class="border-t px-2 py-1">
          <template v-if="!appStore.notifications.length">
            <p class="py-1 text-center">Нет уведомлений</p>
          </template>
          <template v-else>
            <div class="flex max-h-60 flex-col overflow-hidden">
              <div class="space-y-2 overflow-auto">
                <MenuItem v-for="item in appStore.notifications" :key="item.id">
                  <div
                    class="flex flex-row items-center justify-between space-x-2 border-l-4 p-1 text-xs"
                    :class="[
                      item.type === 'success' && 'border-success-500',
                      item.type === 'info' && 'border-info-500',
                      item.type === 'error' && 'border-danger-500',
                      item.type === 'warning' && 'border-yellow-500',
                    ]"
                  >
                    <div>
                      <p v-if="item.title" class="font-semibold">{{ item.title }}</p>
                      <p class="line-clamp-2">
                        {{ item.text }}
                      </p>
                      <p class="text-[0.6rem] text-gray-400">{{ asDateTime(item.timestamp) }}</p>
                    </div>
                    <font-icon
                      class="h-3 w-3 cursor-pointer rounded-full p-1 hover:text-danger-500"
                      icon="fa-solid fa-trash"
                      @click.prevent="handleDelete(item.id)"
                    />
                  </div>
                </MenuItem>
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </el-dropdown>
</template>
