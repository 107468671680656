<script setup lang="ts">
  import { ref } from "vue";
  import { useDropzone, type FileRejectReason } from "vue3-dropzone";

  import { formatBytes } from "@/helpers/formatters";
  import { handleError } from "@/helpers/errorLogger";

  type TypeFileDropzoneProps = {
    multiple?: boolean;
  };

  type TypeFileDropzoneEmits = {
    (e: "add", value: File[]): void;
  };

  const props = withDefaults(defineProps<TypeFileDropzoneProps>(), {
    multiple: false,
  });
  const emits = defineEmits<TypeFileDropzoneEmits>();

  defineSlots<{
    default(): any;
  }>();

  // FIXME: Получение из параметров
  const errorMsg = ref<string[]>([]);
  const imagesTypes = ["png", "jpeg", "tiff"];
  const acceptTypes = ["application/pdf", ".msg", ...imagesTypes.map((v) => `image/${v}`)];
  const minFileSize = 1024; // 1Kb
  const maxFileSize = 30 * 1024 * 1024; // 30Mb

  const onDrop = (acceptFiles: File[], rejectReasons: FileRejectReason[]) => {
    errorMsg.value = [];
    if (rejectReasons.length) {
      rejectReasons.forEach((item) => {
        item.errors.forEach((err) => {
          if (typeof err === "object" && err) {
            switch (err.code) {
              case "file-invalid-type":
                errorMsg.value.push(
                  `Вы пытаетесь загрузить файл недопустимого формата. Допустимые форматы: pdf, msg, ${imagesTypes.join(
                    ", ",
                  )}.`,
                );
                break;
              case "file-too-small":
                errorMsg.value.push(
                  `Вы пытаетесь загрузить файл слишком маленького размера. Допустимый размер ${formatBytes(
                    minFileSize,
                  )}.`,
                );
                break;
              case "file-too-large":
                errorMsg.value.push(
                  `Вы пытаетесь загрузить файл слишком большого размера. Допустимый размер ${formatBytes(
                    maxFileSize,
                  )}.`,
                );
                break;
              case "too-many-files":
                errorMsg.value.push("Доступна загрузка только одного файла.");
                break;
              default:
                handleError(`Неизвестная ошибка при загрузке файла: ${err.code}`);
                errorMsg.value.push("Возникла ошибка при загрузке файла.");
                break;
            }
          }
        });
      });
      return;
    }
    emits("add", acceptFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: props.multiple,
    accept: acceptTypes,
    minSize: minFileSize,
    maxSize: maxFileSize,
  });
</script>

<template>
  <div v-bind="getRootProps()">
    <input v-bind="getInputProps()" />
    <slot>
      <div class="dropzone">
        <h3 v-if="isDragActive">Поместите файлы сюда...</h3>
        <h3 v-else>Поместите файлы сюда или нажмите, чтобы выбрать файлы</h3>
      </div>
    </slot>
    <template v-if="errorMsg.length">
      <p v-for="(msg, idx) in errorMsg" :key="idx" class="text-help mt-1 text-danger-500">
        {{ msg }}
      </p>
    </template>
  </div>
</template>
