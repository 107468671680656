<script setup lang="ts">
  import { ref, watch } from "vue";
  import { Row, FlexRender } from "@tanstack/vue-table";

  type TableRowProps = {
    rows: Row<any>[];
    isRowSelectable: boolean;
    readonly: boolean;
    idKey: string;
    selectedRowId?: string | number;
  };

  type TableRowEmits = {
    (e: "selectRow", row: Row<unknown>): void;
    (e: "doubleClickRow", row: Row<unknown>): void;
  };

  const props = defineProps<TableRowProps>();
  const emits = defineEmits<TableRowEmits>();

  const selectedRow = ref(props.selectedRowId);
  const handleRowClick = (row: Row<any>) => {
    if (!props.isRowSelectable || props.readonly) return;

    selectedRow.value = row.original[props.idKey];
    emits("selectRow", row);
  };

  const handleDoubleRowClick = (row: Row<any>) => {
    if (!props.isRowSelectable || props.readonly) return;

    emits("doubleClickRow", row);
  };

  watch(
    () => props.selectedRowId,
    () => (selectedRow.value = props.selectedRowId),
  );
</script>

<template>
  <tr
    v-for="row in props.rows"
    :key="row.id"
    :class="[
      props.isRowSelectable && !props.readonly && 'cursor-pointer hover:bg-gray-100',
      props.isRowSelectable && row.original[props.idKey] === selectedRow && 'bg-gray-300',
    ]"
    @click="handleRowClick(row)"
    @dblclick="handleDoubleRowClick(row)"
  >
    <td v-for="cell in row.getVisibleCells()" :key="cell.id" class="px-2 py-0.5">
      <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
    </td>
  </tr>
</template>
