<script setup lang="ts">
  import { ref, reactive, watchEffect } from "vue";

  import { useFieldContext } from "@/composable/useFieldContext";
  import InputDatepicker from "./input/InputDatepicker.vue";

  type TypeInputProps = {
    modelValue?: any;
    isDisabled?: boolean;
    isInvalid?: boolean;
  };

  type TypeInputEmits = {
    (e: "update:modelValue", value: any): void;
  };

  const props = withDefaults(defineProps<TypeInputProps>(), {
    modelValue: "",
    isDisabled: false,
    isInvalid: false,
  });
  const emits = defineEmits<TypeInputEmits>();

  const fieldContext = reactive(
    useFieldContext({
      create: false,
      help: "BaseDatepicker",
    }),
  );

  const val = ref(fieldContext.field?.value ?? props.modelValue);

  watchEffect(() => {
    val.value = fieldContext.field?.value ?? props.modelValue;
  });

  const handleDate = (newValue: string | undefined) => {
    fieldContext.field?.handleChange(newValue);
    emits("update:modelValue", newValue);
  };
</script>

<template>
  <InputDatepicker
    v-model="val"
    :uid="fieldContext.id"
    :disabled="props.isDisabled"
    :hide-input-icon="props.isDisabled"
    @update:model-value="handleDate"
    @blur="fieldContext.field?.handleBlur"
  />
</template>
