/**
 * Модуль инициализации env-переменных
 * @remark Если не найдено значение хоть одной переменной,
 * Приложение сразу выбросит ошибку, при инициализации модуля
 * @module
 */

/**
 * Получение env-переменной
 * @throwable
 */

const getEnvVar = (key: string): string => {
  if (import.meta.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }

  return import.meta.env[key] || "";
};

/** Режим запуска программы */
/** Режим разработки */
export const isDevEnv: string = getEnvVar("DEV");
/** Режим продакшена */
export const isProdEnv: string = getEnvVar("PROD");

/** Тип окружения */
export const environment: string = getEnvVar("VITE_ENVIRONMENT");

/** Версия приложения */
export const appVersion: string = getEnvVar("VITE_APP_VERSION_FRONTEND");

/** Название приложения */
export const appName = "biscuit Ins";

/** Название сайта */
export const companyName = 'ООО "СК Согаз-Жизнь"';

/** Название параметра авторизациия пользователя в Localstorage */
export const loggedKey = "isLoggedIn";

/** API entrypoint */
export const apiUrl: string = getEnvVar("VITE_API_BASE_URL");
export const apiPath: string = getEnvVar("VITE_API_PATH");

/** SENTRY */
export const sentryDsn: string = getEnvVar("VITE_SENTRY_DSN");
