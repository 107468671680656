import type { RouteRecordRaw } from "vue-router";

const payoutClaimRoutes: RouteRecordRaw[] = [
  {
    path: "",
    name: "payoutClaim",
    component: () => import("./views/payoutClaim/PayoutClaim.vue"),
    meta: {
      layout: "Horizontal",
      requiresAuth: true,
    },
  },
  {
    path: "registration",
    name: "payoutClaim-registration",
    component: () => import("./views/payoutClaim/PayoutClaimRegistration.vue"),
    meta: {
      layout: "Horizontal",
      requiresAuth: true,
    },
  },
  {
    path: "check-status",
    name: "payoutClaim-status",
    component: () => import("./views/payoutClaim/PayoutClaimCheckStatus.vue"),
    meta: {
      layout: "Horizontal",
      requiresAuth: true,
    },
  },
];

const routes: RouteRecordRaw[] = [
  {
    path: "/contracts-support",
    children: [
      {
        path: "search",
        name: "contracts-support-search",
        component: () => import("./views/SearchContract.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
      {
        path: "payout-claims",
        name: "payoutClaim-list",
        component: () => import("./views/payoutClaim/PayoutClaimTableData.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
      { path: "payout-claim", children: [...payoutClaimRoutes] },
    ],
  },
];

export default routes;
