import type { IContractRegParams, ICodeWordParams } from "./types";

export class ContractRegParams implements IContractRegParams {
  confirmByPep: boolean;
  confirmTelMobile: boolean;
  codeWord: ICodeWordParams;

  constructor() {
    this.confirmByPep = false;
    this.confirmTelMobile = false;
    this.codeWord = {
      isAvailable: false,
      isRequired: false,
      variants: [],
    };
  }
}
