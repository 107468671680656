<script setup lang="ts">
  import { toRefs, computed } from "vue";
  import { Column, Table } from "@tanstack/vue-table";

  import InputDatepicker from "../input/InputDatepicker.vue";

  type RangeValueType = { min?: string; max?: string };

  const props = defineProps<{ column: Column<any, unknown>; table: Table<any> }>();

  const { column } = toRefs(props);
  const columnFilterValue = computed(() => column.value.getFilterValue());
  const filterInputType = computed(() => column.value.columnDef.meta?.filterOptions?.inputType);
  const filterValueType = computed(() => column.value.columnDef.meta?.filterOptions?.valueType);
  const filterSelectOptions = computed(
    () => column.value.columnDef.meta?.filterOptions?.selectOptions,
  );
  const filterSelectType = computed(() => column.value.columnDef.meta?.filterOptions?.selectType);

  function updateRangeValue(name: "min" | "max", val: string) {
    let newRangeVal: RangeValueType | undefined = columnFilterValue.value
      ? (columnFilterValue.value as RangeValueType)
      : {
          min: undefined,
          max: undefined,
        };
    newRangeVal[name] = val ? val : undefined;

    if (!newRangeVal.min && !newRangeVal.max) {
      newRangeVal = undefined;
    }

    column.value.setFilterValue(newRangeVal);
  }
</script>

<template>
  <template v-if="filterInputType === 'select'">
    <MultiSelect
      :value="columnFilterValue"
      :mode="filterSelectType"
      :options="
        filterSelectOptions?.length
          ? filterSelectOptions
          : Array.from(column.getFacetedUniqueValues().keys()).sort()
      "
      no-options-text="Нет данных"
      value-prop="code"
      @change="(val: string) => column.setFilterValue(val)"
    />
  </template>
  <template v-else-if="filterInputType === 'range'">
    <div class="flex flex-col space-y-0.5">
      <template v-if="filterValueType === 'date'">
        <InputDatepicker
          placeholder="с"
          :model-value="((columnFilterValue as RangeValueType)?.min ?? '')"
          @update:model-value="(val) => updateRangeValue('min', val)"
          @cleared="updateRangeValue('min', '')"
        />
        <InputDatepicker
          placeholder="по"
          :model-value="((columnFilterValue as RangeValueType)?.max ?? '')"
          @update:model-value="(val) => updateRangeValue('max', val)"
          @cleared="updateRangeValue('max', '')"
        />
      </template>
      <template v-else>
        <input
          placeholder=">="
          :type="filterValueType"
          :value="(((columnFilterValue as RangeValueType)?.min ?? '') as string)"
          @input="({ target}) => updateRangeValue('min', (target as HTMLInputElement).value)"
        />
        <input
          placeholder="<="
          :type="filterValueType"
          :value="(((columnFilterValue as RangeValueType)?.max ?? '') as string)"
          @input="({target}) => updateRangeValue('max', (target as HTMLInputElement).value)"
        />
      </template>
    </div>
  </template>
  <template v-else>
    <InputDatepicker
      v-if="filterValueType === 'date'"
      :model-value="columnFilterValue"
      @update:model-value="(val) => column.setFilterValue(val)"
    />
    <input
      v-else
      :type="filterValueType"
      :value="columnFilterValue"
      @input="column.setFilterValue(($event.target as HTMLInputElement).value)"
    />
  </template>
</template>
