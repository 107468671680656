<script lang="ts">
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "LayoutHorizontal",
  });
</script>

<script setup lang="ts">
  import { useAppStore } from "@/modules/app";

  import { AppLayer } from "@/modules/app";
  import AppNavbar from "./horizontal/AppNavbar.vue";
  import AppFooter from "./horizontal/AppFooter.vue";

  const appStore = useAppStore();
</script>

<template>
  <div class="flex h-screen w-full flex-col">
    <AppNavbar v-if="!appStore.isError && !appStore.isLoading" />
    <main class="grow overflow-hidden px-2 py-2 sm:px-4">
      <AppLayer>
        <router-view />
      </AppLayer>
    </main>
    <AppFooter class="relative w-full shrink-0 bg-gray-100" />
  </div>
</template>
