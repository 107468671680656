<script setup lang="ts">
  import { ref, reactive, watch, watchEffect } from "vue";
  import { RadioGroup, RadioGroupOption, RadioGroupLabel } from "@headlessui/vue";

  import { useFieldContext } from "@/composable/useFieldContext";

  type TypeRadioGroupProps = {
    items: any[];
    modelValue?: string | number | boolean;
    isDisabled?: boolean;
  };
  type TypeRadioGroupEmits = {
    (e: "update:modelValue", value: any): void;
  };

  const props = defineProps<TypeRadioGroupProps>();
  const emits = defineEmits<TypeRadioGroupEmits>();

  const fieldContext = reactive(
    useFieldContext({
      create: false,
      help: "BaseRadioGroup",
    }),
  );

  const val = ref<string | number | boolean | Record<string, any> | undefined>(
    fieldContext.field?.value ?? props.modelValue,
  );

  watch(val, () => {
    fieldContext.field?.handleBlur();
    fieldContext.field?.handleChange(val.value);
    emits("update:modelValue", val.value);
  });

  watchEffect(() => {
    val.value = fieldContext.field?.value ?? props.modelValue;
  });
</script>

<template>
  <RadioGroup
    :id="fieldContext.id"
    v-model="val"
    class="flex w-full flex-wrap"
    :disabled="props.isDisabled"
  >
    <template v-for="(item, idx) in props.items" :key="item.code">
      <RadioGroupOption v-slot="{ checked }" :value="item.code" :disabled="item.isDisabled">
        <RadioGroupLabel as="template">
          <span
            class="radio-label rounded-none"
            :class="[
              checked && 'checked',
              (props.isDisabled || item.isDisabled) && 'disabled',
              idx === 0 && 'rounded-l',
              idx === props.items.length - 1 && 'rounded-r',
            ]"
          >
            {{ item.name }}
          </span>
        </RadioGroupLabel>
      </RadioGroupOption>
    </template>
  </RadioGroup>
</template>
