<script setup lang="ts">
  import { ref, computed, watch } from "vue";
  import { Table } from "@tanstack/vue-table";

  type TablePaginationProps = {
    table: Table<unknown>;
    pageSizes: number[];
    totalItems: number;
    showExportExcel?: boolean;
    exportExcelIsLoading?: boolean;
  };

  type TablePaginationEmits = {
    (e: "reloadTable"): void;
    (e: "exportItems", formatType: "excel"): void;
  };

  const props = withDefaults(defineProps<TablePaginationProps>(), {
    showExportExcel: true,
    exportExcelIsLoading: false,
  });
  const emits = defineEmits<TablePaginationEmits>();

  const pageNumber = ref(1);
  watch(
    () => props.table.getState().pagination.pageIndex,
    (newValue) => {
      pageNumber.value = newValue + 1;
    },
  );

  const handleGoToPage = (e: Event) => {
    const pageValue = (e.target as HTMLInputElement).value;

    let page = pageValue ? Number(pageValue) - 1 : 0;
    page = page > props.table.getPageCount() - 1 ? props.table.getPageCount() - 1 : page;

    props.table.setPageIndex(page);
  };

  const handlePageSizeChange = (pageSize: number) => {
    props.table.setPageSize(pageSize);
    props.table.resetPageIndex();
  };

  const recordInfo = computed(() => {
    const { pageIndex, pageSize } = props.table.getState().pagination;
    const firstRecordOnPage = pageIndex * pageSize + 1;
    const lastRecordOnPage = Math.min(props.totalItems, (pageIndex + 1) * pageSize);

    return `Записи с ${firstRecordOnPage} по ${lastRecordOnPage}. Всего ${props.totalItems}`;
  });
</script>

<template>
  <div class="flex flex-col items-center justify-between space-y-2 py-1 sm:flex-row sm:space-y-0">
    <div class="flex flex-col items-center gap-3 sm:flex-row">
      <div class="flex items-center gap-1">
        <el-button
          class="btn"
          :is-disabled="!table.getCanPreviousPage()"
          @click="() => table.setPageIndex(0)"
        >
          <font-icon icon="fa-solid fa-backward-step" />
        </el-button>
        <el-button
          class="btn"
          :is-disabled="!table.getCanPreviousPage()"
          @click="() => table.previousPage()"
        >
          <font-icon icon="fa-solid fa-caret-left" />
        </el-button>
        <div class="flex items-center gap-1">
          <span>Страница</span>
          <input v-model="pageNumber" class="w-12" @change="handleGoToPage" />
          <span>из {{ table.getPageCount() }}</span>
        </div>
        <el-button
          class="btn"
          :is-disabled="!table.getCanNextPage()"
          @click="() => table.nextPage()"
        >
          <font-icon icon="fa-solid fa-caret-right" />
        </el-button>
        <el-button
          class="btn"
          :is-disabled="!table.getCanNextPage()"
          @click="() => table.setPageIndex(table.getPageCount() - 1)"
        >
          <font-icon icon="fa-solid fa-forward-step" />
        </el-button>
      </div>

      <div class="flex items-center gap-1">
        <span>Число записей на странице: </span>
        <div>
          <el-button
            v-for="item in props.pageSizes"
            :key="item"
            :class="[item === table.getState().pagination.pageSize ? 'btn-primary' : 'btn']"
            @click="handlePageSizeChange(item)"
          >
            {{ item }}
          </el-button>
        </div>
      </div>

      <div class="flex items-center gap-1">
        <el-button v-tooltip="'Обновить'" class="btn" @click="emits('reloadTable')">
          <font-icon icon="fa-solid fa-rotate" />
        </el-button>
        <el-button
          v-if="props.showExportExcel"
          class="btn"
          :is-loading="props.exportExcelIsLoading"
          @click="emits('exportItems', 'excel')"
        >
          <font-icon icon="fa-solid fa-file-excel" class="pr-1" />
          <span>Выгрузить в Excel</span>
        </el-button>
      </div>
    </div>
    <div class="flex flex-col items-center sm:flex-row">
      <span>{{ recordInfo }}</span>
    </div>
  </div>
</template>
