<script setup lang="ts">
  import { computed } from "vue";

  type TypeButtonProps = {
    fullwidth?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
  };

  const props = defineProps<TypeButtonProps>();

  const classes = computed(() => [
    props.fullwidth && "w-full",
    props.isDisabled && "is-disabled cursor-not-allowed",
  ]);
</script>

<template>
  <button
    type="button"
    class="relative rounded border p-1 px-2 text-center"
    :class="classes"
    :disabled="props.isDisabled || props.isLoading"
  >
    <slot></slot>
    <template v-if="props.isLoading">
      <div class="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-inherit">
        <div
          class="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent"
          role="status"
        ></div>
      </div>
    </template>
  </button>
</template>
