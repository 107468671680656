<template>
  <section class="flex h-full items-center p-16">
    <div class="container mx-auto my-8 flex flex-col items-center justify-center px-5">
      <div class="max-w-md text-center">
        <h2 class="mb-8 text-9xl font-extrabold">404</h2>
        <p class="text-2xl font-semibold md:text-3xl">Страница не найдена</p>
        <el-button class="btn-primary mt-8 text-lg" @click="$router.go(-1)">
          Вернуться назад
        </el-button>
      </div>
    </div>
  </section>
</template>
