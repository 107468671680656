import { ref, unref, provide, inject, InjectionKey } from "vue";
import type { MaybeRef } from "@vueuse/core";
import { useField, FieldContext } from "vee-validate";
import { defu } from "defu";

export type TypeFieldContext = ReturnType<typeof createFieldContext>;
export const useFieldSymbolContext = Symbol() as InjectionKey<TypeFieldContext>;

let fieldId = 0;

function createFieldContext<TValue = unknown>(id?: MaybeRef<string | undefined>) {
  const _id = unref(id);
  const $id = ref(id ?? `field-${++fieldId}`);
  const field = ref<FieldContext<TValue>>();

  if (_id) {
    field.value = useField(() => _id);
  }

  const fieldContext = {
    id: $id,
    field,
  };

  provide(useFieldSymbolContext, fieldContext);

  return fieldContext;
}

interface IFieldContextOption {
  id?: MaybeRef<string | undefined>;
  create?: MaybeRef<boolean>;
  inherit?: MaybeRef<boolean>;
  help?: MaybeRef<string>;
}

export function useFieldContext(options: IFieldContextOption = {}) {
  const _options = defu(options, {
    create: true,
    inherit: true,
  });

  if (unref(_options.inherit)) {
    const fieldContext = inject(useFieldSymbolContext, undefined);
    if (fieldContext) {
      return fieldContext;
    }
  }

  const _help = unref(_options.help) ? `${unref(_options.help)}: ` : "";

  if (!unref(_options.create)) {
    throw new Error(
      `${_help}useFieldContext (create = false) must be used inside a el-field component`,
    );
  }

  return createFieldContext(_options.id);
}
