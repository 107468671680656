<script setup lang="ts">
  import { ref, computed } from "vue";
  import { useRouter } from "vue-router";
  import { useQuery, useMutation } from "@tanstack/vue-query";
  import { useAbility } from "@casl/vue";

  import { IHttpResponse } from "@/types/Api";
  import notify from "@/composable/useNotify";
  import { ability } from "@/plugins/casl/AppAbility";
  import { useAppStore } from "@/modules/app";
  import apiClient from "../api";
  import { IProduct } from "../types/Product";

  const router = useRouter();
  const { can } = useAbility();
  const appStore = useAppStore();

  const canCreateContract = computed(() =>
    can(ability.POLICY_CREATE.action, ability.POLICY_CREATE.subject),
  );

  const { data: products, isLoading: productsIsLoading } = useQuery<
    IHttpResponse<IProduct[]>,
    Error,
    IProduct[]
  >({
    queryKey: ["products"],
    queryFn: () => apiClient.getProducts(),
    enabled: computed(() => appStore.mainDataLoaded && canCreateContract.value),
    retry: 1,
    meta: {
      errorsTitle: "Загрузка доступных продуктов",
    },
    select(data) {
      return data.data;
    },
  });

  // Создание договора
  const waiting = ref(false); // Для ожидания открытия формы договора
  const selectedProduct = ref<string | undefined>();
  const { mutate: createContract, isPending: createInProcess } = useMutation({
    mutationFn: (productType: string) => apiClient.create(productType),
    retry: 0,
    onError: () => {
      notify({ type: "error", text: "Возникла ошибка при создании договора" });
    },
  });

  const handleCreateContract = async (productType: string) => {
    if (!productType || createInProcess.value || waiting.value) return;
    appStore.selectedContract.type = productType;
    selectedProduct.value = productType;

    createContract(productType, {
      onSuccess(data) {
        appStore.selectedContract.id = data.data.orderNumber;
        appStore.selectedContract.date = data.data.date;
        appStore.selectedContract.isReinvest = false;

        router.push({ name: `contract-${productType}` });
        waiting.value = true;
      },
    });
  };
</script>

<template>
  <div class="fixed inset-0">
    <div class="flex min-h-full items-center justify-center">
      <el-loader :active="canCreateContract && productsIsLoading">
        <el-card class="min-h-[250px] sm:min-w-[480px]">
          <template v-if="!canCreateContract || !products">
            <h3 class="py-2 text-center text-base font-semibold">Нет доступных продуктов</h3>
          </template>
          <template v-else>
            <h3 class="block-header text-base font-semibold">Выберите страховой продукт</h3>
            <ul role="list" class="divide-y divide-gray-200 py-2 text-sm">
              <li
                v-for="product in products"
                :key="product.code"
                @click="handleCreateContract(product.code)"
              >
                <el-loader
                  :active="selectedProduct == product.code && (createInProcess || waiting)"
                  size="xs"
                >
                  <div
                    class="flex cursor-pointer items-center rounded px-2 py-1 transition duration-100 ease-in-out hover:bg-gray-100"
                  >
                    <div class="flex min-w-0 flex-1 flex-col">
                      <p class="font-semibold">{{ product.name }}</p>
                      <p class="text-help">Страховой продукт</p>
                    </div>
                  </div>
                </el-loader>
              </li>
            </ul>
          </template>
        </el-card>
      </el-loader>
    </div>
  </div>
</template>
