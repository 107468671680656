<script setup lang="ts">
  import { ref, reactive, watchEffect, watch } from "vue";

  import { useFieldContext } from "@/composable/useFieldContext";

  type TypeInputProps = {
    modelValue?: any;
    isDisabled?: boolean;
    trueValue?: string | number | boolean;
    falseValue?: string | number | boolean;
    label?: string;
    required?: boolean;
  };

  type TypeInputEmits = {
    (e: "update:modelValue", value: any): void;
    (e: "change", value: any): void;
  };

  const props = withDefaults(defineProps<TypeInputProps>(), {
    modelValue: undefined,
    trueValue: true,
    falseValue: false,
    isDisabled: false,
    label: undefined,
  });
  const emits = defineEmits<TypeInputEmits>();

  const fieldContext = reactive(
    useFieldContext({
      create: false,
      help: "BaseCheckbox",
    }),
  );

  const val = ref(fieldContext.field?.value ?? props.modelValue);

  const changeValue = (e: Event) => {
    const newValue = (e.target as HTMLInputElement).checked;
    emits("change", newValue);
  };

  watch(val, () => {
    fieldContext.field?.handleBlur();
    fieldContext.field?.handleChange(val.value);
    emits("update:modelValue", val.value);
  });
  watchEffect(() => {
    val.value = fieldContext.field?.value ?? props.modelValue;
  });
</script>

<template>
  <div class="flex items-center">
    <input
      :id="fieldContext.id"
      v-bind="$attrs"
      v-model="val"
      :true-value="props.trueValue"
      :false-value="props.falseValue"
      type="checkbox"
      class="checkbox"
      :class="[props.isDisabled && 'is-disabled']"
      :disabled="props.isDisabled"
      @change="changeValue"
    />
    <label v-if="props.label" class="pl-2" :class="props.required && 'is-required'">
      {{ props.label }}
    </label>
  </div>
</template>
