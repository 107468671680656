<script setup lang="ts">
  import { companyName, appName, appVersion } from "@/config";

  const curYear: number = new Date().getFullYear();
</script>

<template>
  <footer class="text-center">
    <hr />
    <span class="text-help mt-1 text-gray-500">
      {{ appName }}. {{ companyName }}. © {{ curYear }}. Все права защищены. Версия:
      {{ appVersion }}
    </span>
  </footer>
</template>
