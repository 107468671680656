import { createApp } from "vue";
import "reflect-metadata";

import router from "@/router";
import pinia from "@/plugins/pinia";
import App from "./App.vue";
import "./styles/index.scss";

import { register as regLayouts } from "./layouts/register";
import { register as regComponents } from "./ui/register";
import { regPlugin as regNotifications } from "@/plugins/notiwind";
import { regPlugin as regMultiselect } from "@/plugins/multiselect";
import { regPlugin as regDatepicker } from "@/plugins/vue-datepicker";
import { regPlugin as regCasl } from "@/plugins/casl";
import { regPlugin as regVueQuery } from "@/plugins/vue-query";
import { regPlugin as regFloatingVue } from "@/plugins/floating-vue";
import { regPlugin as regSentry } from "@/plugins/sentry";
import FontAwesomeIcon from "@/plugins/fontawesome-icons";

const app = createApp(App);

app.component("FontIcon", FontAwesomeIcon);
app.use(router).use(pinia);

regLayouts(app);
regComponents(app);
regNotifications(app);
regMultiselect(app);
regDatepicker(app);
regCasl(app);
regVueQuery(app);
regFloatingVue(app);
regSentry(app, router);

app.mount("#app");
