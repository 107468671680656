<script setup lang="ts">
  import useAppStore from "../store";

  import AppErrorLayer from "./AppErrorLayer.vue";
  import ModalSalesPointNotify from "./ModalSalesPointNotify.vue";

  const appStore = useAppStore();
</script>

<template>
  <div class="relative flex h-full flex-col">
    <el-loader :active="appStore.isLoading" grey full-screen>
      <slot></slot>
    </el-loader>
    <ModalSalesPointNotify />
    <AppErrorLayer v-if="appStore.isError" />
  </div>
</template>
