<script setup lang="ts">
  import { computed } from "vue";
  import { useRoute } from "vue-router";

  import AppNotification from "@/components/AppNotification.vue";

  const route = useRoute();

  const layout = computed(() => {
    const routeLayout = route?.meta?.layout;

    if (routeLayout) {
      return `Layout${routeLayout}`;
    }
    return "div";
  });
</script>

<template>
  <AppNotification />
  <component :is="layout">
    <router-view />
  </component>
</template>
