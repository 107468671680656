<script setup lang="ts">
  import { ref, reactive, watch, watchEffect } from "vue";
  import { RadioGroup, RadioGroupOption, RadioGroupLabel } from "@headlessui/vue";

  import { useFieldContext } from "@/composable/useFieldContext";

  type TypeToggleSwitchProps = {
    modelValue?: boolean;
    labelTrue?: string;
    labelFalse?: string;
    falseValueFirst?: boolean;
    isDisabled?: boolean;
  };

  type TypeToggleSwitchEmits = {
    (e: "update:modelValue", value: any): void;
  };

  const props = withDefaults(defineProps<TypeToggleSwitchProps>(), {
    modelValue: undefined,
    labelTrue: "Да",
    labelFalse: "Нет",
    falseValueFirst: false,
    isDisabled: false,
  });
  const emits = defineEmits<TypeToggleSwitchEmits>();

  const fieldContext = reactive(
    useFieldContext({
      create: false,
      help: "BaseToggleSwitch",
    }),
  );

  const trueLabelClasses = [
    "radio-label",
    props.falseValueFirst ? "rounded-l-none" : "rounded-r-none",
  ];
  const falseLabelClasses = [
    "radio-label",
    !props.falseValueFirst ? "rounded-l-none" : "rounded-r-none",
  ];

  const val = ref<any>(fieldContext.field?.value ?? props.modelValue);

  watch(val, () => {
    fieldContext.field?.handleBlur();
    fieldContext.field?.handleChange(val.value);
    emits("update:modelValue", val.value);
  });

  watchEffect(() => {
    val.value = fieldContext.field?.value ?? props.modelValue;
  });
</script>

<template>
  <RadioGroup
    :id="fieldContext.id"
    v-model="val"
    class="grid grid-cols-2"
    :disabled="props.isDisabled"
  >
    <RadioGroupOption v-slot="{ checked }" :value="true">
      <RadioGroupLabel as="template">
        <span
          :class="[
            trueLabelClasses,
            checked && 'checked',
            props.isDisabled && 'disabled',
            !props.isDisabled && 'hover:border-primary-500',
          ]"
        >
          {{ props.labelTrue }}
        </span>
      </RadioGroupLabel>
    </RadioGroupOption>
    <RadioGroupOption
      v-slot="{ checked }"
      :value="false"
      :class="{ 'order-first': props.falseValueFirst }"
    >
      <RadioGroupLabel as="template">
        <span
          :class="[
            falseLabelClasses,
            checked && 'checked',
            props.isDisabled && 'disabled',
            !props.isDisabled && 'hover:border-primary-500',
          ]"
        >
          {{ props.labelFalse }}
        </span>
      </RadioGroupLabel>
    </RadioGroupOption>
  </RadioGroup>
</template>
