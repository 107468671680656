import { App } from "vue";
import { isAxiosError } from "axios";
import {
  QueryClient,
  QueryCache,
  MutationCache,
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from "@tanstack/vue-query";

import { IHttpBaseResponse, TQueryMeta } from "@/types/Api";
import notify from "@/composable/useNotify";
import { handleError } from "@/helpers/errorLogger";
import { useAppStore } from "@/modules/app";
import { useAuthStore } from "@/modules/auth";

const httpErrorHandler = (error: Error, queryMeta?: TQueryMeta) => {
  const defaultErrorMsg = "Возникла ошибка";
  let errorMsg = defaultErrorMsg;
  let errorTitle = undefined;

  if (isAxiosError(error)) {
    if (error.code === "ERR_NETWORK") {
      errorMsg = "Сервис временно недоступен. Попробуйте повторить попытку позже";
      handleError("Нет подключения к api", error.toJSON());
    } else if (error.status === 401 || (error.response && error.response.status === 401)) {
      notify({ type: "error", text: "Сессия истекла, необходимо перезайти" }, 10);
      if (queryMeta?.enableAutoLogout) {
        const authStore = useAuthStore();
        authStore.logout();
      }
      return;
    } else if (error.response) {
      const responseData: IHttpBaseResponse = error.response.data;
      if (!responseData.errorMessage) {
        handleError("Ошибка выполнения запроса к api", error.toJSON());
      }

      errorMsg = responseData.errorMessage ?? defaultErrorMsg;
      errorTitle = queryMeta?.errorsTitle;
    }
  }
  if (queryMeta?.enableAppErrorLayer) {
    const appStrore = useAppStore();
    appStrore.isError = true;
  }

  notify({ type: "error", text: errorMsg, title: errorTitle });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => httpErrorHandler(error, query.meta),
  }),
  mutationCache: new MutationCache({
    onError: (error, _, __, mutation) => httpErrorHandler(error, mutation.meta),
  }),
});

export const regPlugin = (app: App) => {
  const vueQueryPluginOptions: VueQueryPluginOptions = {
    queryClient: queryClient,
  };

  app.use(VueQueryPlugin, vueQueryPluginOptions);
};
