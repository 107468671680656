import { App } from "vue";

export const register = (app: App) => {
  const components = import.meta.glob("./Base*.vue", { eager: true });
  Object.entries(components).forEach(([path, component]) => {
    // Just get the file name itself, remove the .vue extension, and remove the "Base" at the front of the file name
    const pathSplit = path.split("/");
    const fileName = pathSplit[pathSplit.length - 1].split(".vue")[0].split("Base")[1];

    // Convert to kebab-case and register with a prefix
    const kebab = fileName.replace(/([a-z0–9])([A-Z])/g, "$1-$2").toLowerCase();
    app.component(`el-${kebab}`, component.default);
  });
};
