const locale = (navigator && navigator.language) || "ru-RU";

export const asPercent = (val: string | number, minDig = 2, maxDig = 4) => {
  const newValue = Number(val) || 0;
  const stringValue = newValue.toLocaleString(locale, {
    minimumFractionDigits: minDig,
    maximumFractionDigits: maxDig,
  });

  return `${stringValue}%`;
};

export const asIntenger = (val: string | number) => {
  const newVal = Number(val);
  return newVal.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const asDecimal = (val: string | number, minDig = 2, maxDig = 2) => {
  const newVal = Number(val);
  return newVal.toLocaleString(locale, {
    minimumFractionDigits: minDig,
    maximumFractionDigits: maxDig,
  });
};

export const asDate = (val: string | Date) => {
  return new Date(val).toLocaleDateString(locale);
};

export const asDateTime = (val: string | Date | number) => {
  return new Date(val).toLocaleString(locale);
};

export const asCurrency = (val: string | number, cur = "rub") => {
  const newValue = Number(val) || 0;

  return newValue.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: cur,
  });
};

export const formatBytes = (val: string | number, b = 2) => {
  const newValue = Number(val) || 0;

  if (!+newValue) return "0 Bytes";
  const c = b < 0 ? 0 : b;
  const d = Math.floor(Math.log(newValue) / Math.log(1024));
  return `${parseFloat((newValue / 1024 ** d).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
};
