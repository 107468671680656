import { computed } from "vue";
import { useAbility } from "@casl/vue";

import { TypeNavigations, IMenu, IMenuItem } from "./types";
import policies from "./policies";
import contractSupport from "./contractSupport";

const navigation: TypeNavigations = [policies, contractSupport];

function checkAvailable(item: IMenuItem) {
  const { can } = useAbility();
  return item.permission ? can(item.permission.action, item.permission.subject) : true;
}

function addMenuItem(menu: TypeNavigations, item: IMenu | IMenuItem) {
  if ("children" in item) {
    const itemWithChilden = {
      header: item.header,
      children: [],
    };
    item.children.forEach((chdItem: IMenuItem) => addMenuItem(itemWithChilden.children, chdItem));
    if (itemWithChilden.children.length) menu.push(itemWithChilden);
  } else if (checkAvailable(item)) {
    menu.push(item);
  }
}

const availableMenu = computed<TypeNavigations>(() => {
  const menu: TypeNavigations = [];
  navigation.forEach((item) => {
    addMenuItem(menu, item);
  });

  return menu;
});

export default availableMenu;
