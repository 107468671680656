const routes = [
  {
    path: "/contracts",
    children: [
      {
        path: "invest",
        name: "contracts-invest",
        component: () => import("./views/contracts/TableContractsInvest.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
      {
        path: "nsg",
        name: "contracts-nsg",
        component: () => import("./views/contracts/TableContractsNsg.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
      {
        path: "fixincome",
        name: "contracts-fixincome",
        component: () => import("./views/contracts/TableContractsFixIncome.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
      {
        path: "underwriting",
        name: "contracts-underwriting",
        component: () => import("./views/contracts/TableContractsUnderwriting.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
      {
        path: "confirmed",
        name: "contracts-confirmed",
        component: () => import("./views/contracts/TableContractsConfirmed.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
