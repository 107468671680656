<script setup lang="ts">
  import { MenuItem } from "@headlessui/vue";

  import useAuthStore from "../store";

  const authStore = useAuthStore();
</script>

<template>
  <div class="flex flex-row items-center">
    <span class="hidden px-1 text-gray-300 sm:inline-block">{{ authStore.userName }}</span>
    <el-dropdown>
      <template #button>
        <div class="flex items-center text-gray-300">
          <font-icon
            class="rounded-full p-1 hover:bg-primary-600 hover:text-white"
            icon="fa-solid fa-user"
          />
        </div>
      </template>
      <template #content>
        <div class="text-gray-900">
          <div class="border-b px-3 py-2 sm:hidden">
            <p class="truncate font-medium">{{ authStore.userName }}</p>
          </div>
          <div class="border-t py-1">
            <MenuItem v-slot="{ active }" class="cursor-pointer">
              <el-app-link
                class="block px-3 py-1 transition duration-150 ease-in-out"
                :class="[active ? 'bg-gray-100' : '']"
                :to="{ name: 'user-profile' }"
              >
                Профиль
              </el-app-link>
            </MenuItem>
            <MenuItem v-slot="{ active }" class="cursor-pointer">
              <a
                class="block px-3 py-1 transition duration-150 ease-in-out"
                :class="[active ? 'bg-danger-50' : '']"
                @click="authStore.logout"
              >
                Выход
              </a>
            </MenuItem>
          </div>
        </div>
      </template>
    </el-dropdown>
  </div>
</template>
