<script setup lang="ts">
  import { ref, reactive, computed, watch, watchEffect, toRefs } from "vue";
  import { vMaska, type MaskOptions, type MaskInputOptions, type MaskType } from "maska";

  import { useFieldContext } from "@/composable/useFieldContext";

  type TypeInputProps = {
    modelValue?: any;
    isDisabled?: boolean;
    isInvalid?: boolean;
    showOpenButton?: boolean;
    maskaFormat?: MaskType;
    postFormat?: (value: string) => string;
  };

  type TypeInputEmits = {
    (e: "update:modelValue", value: any): void;
    (e: "change", value: any): void;
    (e: "input", value: any): void;
    (e: "open"): void;
  };

  const props = withDefaults(defineProps<TypeInputProps>(), {
    modelValue: undefined,
    isDisabled: false,
    isInvalid: false,
    showOpenButton: false,
    maskaFormat: "",
    postFormat: (value: string) => value.trim(),
  });
  const emits = defineEmits<TypeInputEmits>();

  const { maskaFormat, postFormat } = toRefs(props);

  const fieldContext = reactive(
    useFieldContext({
      create: false,
      help: "BaseInput",
    }),
  );

  const maskaOptions = computed<MaskOptions & MaskInputOptions>(() => {
    return {
      mask: maskaFormat.value,
      tokens: {
        D: { pattern: /[0-9]/, repeated: true },
        Z: { pattern: /[А-Яа-яЁё -]/, multiple: true },
        Y: { pattern: /[А-Яа-яЁё0-9 -]/, multiple: true },
        X: { pattern: /[a-zA-Z -]/, multiple: true },
        W: { pattern: /[a-zA-Z0-9 -]/, multiple: true },
      },
    };
  });

  const val = ref(fieldContext.field?.value ?? props.modelValue);

  const hasError = computed(() =>
    fieldContext?.field ? Boolean(fieldContext?.field?.errorMessage) : false,
  );

  const handleChange = (e: Event) => {
    const newValue = postFormat.value((e.target as HTMLInputElement).value);
    fieldContext.field?.handleChange(newValue);
    emits("change", newValue);
  };

  const handleInput = (e: Event) => {
    const newValue = (e.target as HTMLInputElement).value;
    emits("input", newValue);
  };

  watch(val, () => {
    emits("update:modelValue", val.value);
  });
  watchEffect(() => {
    val.value = fieldContext.field?.value ?? props.modelValue;
  });
</script>

<template>
  <div class="relative flex items-center justify-end">
    <input
      v-bind="$attrs"
      :id="fieldContext.id"
      v-model="val"
      v-maska:[maskaOptions]
      autocomplete="off"
      :class="{ 'is-invalid': props.isInvalid || hasError }"
      :disabled="props.isDisabled"
      @keypress.enter.prevent
      @blur="fieldContext.field?.handleBlur"
      @change="handleChange"
      @input="handleInput"
    />
    <font-icon
      v-if="props.showOpenButton"
      icon="fa-solid fa-magnifying-glass"
      class="absolute mr-2 cursor-pointer"
      @click="emits('open')"
    />
  </div>
</template>
