<script setup lang="ts">
  import { ref, reactive, watch, watchEffect } from "vue";

  import { useFieldContext } from "@/composable/useFieldContext";

  type TypeTextareaProps = {
    modelValue?: any;
    isDisabled?: boolean;
  };
  type TypeTextareaEmits = {
    (e: "update:modelValue", value: any): void;
    (e: "change", value: any): void;
  };
  const props = withDefaults(defineProps<TypeTextareaProps>(), {
    modelValue: undefined,
    isDisabled: false,
  });
  const emits = defineEmits<TypeTextareaEmits>();

  const fieldContext = reactive(
    useFieldContext({
      create: false,
      help: "BaseTextarea",
    }),
  );

  const val = ref(fieldContext.field?.value ?? props.modelValue);

  watch(val, () => {
    emits("update:modelValue", val.value);
  });
  watchEffect(() => {
    val.value = fieldContext.field?.value ?? props.modelValue;
  });

  const handleChange = (e: Event) => {
    const newValue = (e.target as HTMLInputElement).value;
    fieldContext.field?.handleChange(newValue);
    emits("change", newValue);
  };
</script>

<template>
  <textarea
    :id="fieldContext.id"
    v-model="val"
    :disabled="props.isDisabled"
    @blur="fieldContext.field?.handleBlur"
    @change="handleChange"
  />
</template>
