<script setup lang="ts">
  import { ref, computed } from "vue";
  import { Table } from "@tanstack/vue-table";

  import TableFilterInput from "./TableFilterInput.vue";

  type TableFiltersProps = {
    table: Table<unknown>;
    totalColumns: number;
  };

  type TableFiltersEmits = {
    (e: "filtersUpdated", filtersApply: boolean): void;
  };

  const props = defineProps<TableFiltersProps>();
  const emits = defineEmits<TableFiltersEmits>();

  defineSlots<{
    additionalInfo(): any;
  }>();

  const filtersApply = ref(false);
  const filtersSet = computed(() => !!props.table.getState().columnFilters.length);

  const applyColumnFilters = () => {
    if (!filtersSet.value) return;
    filtersApply.value = true;
    emits("filtersUpdated", filtersApply.value);
  };

  const clearColumnFilters = () => {
    props.table.resetColumnFilters();
    if (filtersApply.value) {
      filtersApply.value = false;
      emits("filtersUpdated", filtersApply.value);
    }
  };

  const colSpan = computed(() => (props.totalColumns > 6 ? 6 : 1));
</script>

<template>
  <tr v-for="headerGroup in props.table.getHeaderGroups()" :key="headerGroup.id" class="align-top">
    <th
      v-for="header in headerGroup.headers"
      :key="header.id"
      :colSpan="header.colSpan"
      scope="col"
      class="p-1 font-normal"
    >
      <template v-if="header.column.getCanFilter()">
        <TableFilterInput :column="header.column" :table="table" />
      </template>
    </th>
  </tr>
  <tr>
    <th scope="col" :colspan="colSpan" class="sticky left-0 w-full px-1 pb-1">
      <div
        class="flex flex-col items-start space-y-1 sm:flex-row sm:items-center sm:space-x-2 sm:space-y-0"
      >
        <div class="text-left font-normal sm:space-x-1">
          <el-button class="btn" :is-disabled="!filtersSet" @click="applyColumnFilters">
            Отфильтровать
          </el-button>
          <el-button
            class="btn"
            :is-disabled="!filtersSet && !filtersApply"
            @click="clearColumnFilters"
          >
            Очистить
          </el-button>
        </div>
        <slot name="additionalInfo"></slot>
      </div>
    </th>
    <th scope="col" :colspan="totalColumns - colSpan"></th>
  </tr>
</template>
