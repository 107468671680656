import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faXmark,
  faBars,
  faCheck,
  faInfo,
  faCircleInfo,
  faExclamation,
  faSpinner,
  faChevronUp,
  faChevronDown,
  faUser,
  faBackwardStep,
  faForwardStep,
  faCaretUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faRotate,
  faFileExcel,
  faDownload,
  faUpload,
  faTrash,
  faCirclePlus,
  faFilePen,
  faTrashCan,
  faCircleCheck,
  faCircleXmark,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFileLines,
  faFilePdf,
  faFileImage,
  faCircleQuestion,
  faBell,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faXmark,
  faBars,
  faCheck,
  faExclamation,
  faInfo,
  faCircleInfo,
  faSpinner,
  faChevronUp,
  faChevronDown,
  faUser,
  faBackwardStep,
  faForwardStep,
  faCaretUp,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faRotate,
  faFileExcel,
  faFileLines,
  faFilePdf,
  faFileImage,
  faDownload,
  faUpload,
  faTrash,
  faCirclePlus,
  faFilePen,
  faTrashCan,
  faCircleQuestion,
  faBell,
  faCircleCheck,
  faCircleXmark,
  faMagnifyingGlass,
);

export default FontAwesomeIcon;
