<script setup lang="ts">
  import { computed } from "vue";
  import { RouterLink, useLink, type RouterLinkProps } from "vue-router";

  interface ILinkProps extends RouterLinkProps {
    inactiveClass?: string;
  }

  const props = defineProps<ILinkProps>();

  const { navigate, href, isActive } = useLink(props);

  const isExternalLink = computed(
    () => typeof props.to === "string" && props.to.startsWith("http"),
  );
</script>

<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="(to as string)" target="_blank"></a>
  <router-link v-else v-bind="props" custom>
    <a
      v-bind="$attrs"
      :href="href"
      :class="isActive ? activeClass : inactiveClass"
      @click="navigate"
    >
      <slot />
    </a>
  </router-link>
</template>
