import { ref, reactive } from "vue";
import { acceptHMRUpdate, defineStore } from "pinia";

import { type TFieldRequire } from "@/types/Base";
import { type TNotificationSchema } from "@/plugins/notiwind";
import { type IContractRegParams, ContractRegParams } from "@/modules/auth";

type TTimeStamp = {
  timestamp: number;
};
type TNotification = TFieldRequire<TNotificationSchema, "id"> & TTimeStamp;

const useAppStore = defineStore("app", () => {
  const isLoading = ref(false);
  const isError = ref(false);
  const mainDataLoaded = ref(false);
  const modalSalesPointShown = ref(false);
  const notifications = ref<TNotification[]>([]);

  const сontractRegistrationParams = ref<IContractRegParams>(new ContractRegParams());

  // Что бы contractStore не инициализировался раньше элемента form
  const selectedContract: {
    type: string;
    id: string;
    date: string;
    confirmed?: boolean;
    isReinvest?: boolean;
  } = reactive({
    type: "",
    id: "",
    date: "",
    confirmed: false,
    isReinvest: false,
  });

  const clearSelectedContract = () => {
    selectedContract.type = "";
    selectedContract.id = "";
    selectedContract.date = "";
    selectedContract.confirmed = false;
    selectedContract.isReinvest = false;
  };

  const clearContractRegParams = () => {
    сontractRegistrationParams.value = new ContractRegParams();
  };

  const setInitState = () => {
    isLoading.value = false;
    isError.value = false;
    mainDataLoaded.value = false;
    modalSalesPointShown.value = false;
    notifications.value = [];
    clearSelectedContract();
    clearContractRegParams();
  };

  return {
    isLoading,
    isError,
    mainDataLoaded,
    selectedContract,
    modalSalesPointShown,
    сontractRegistrationParams,

    clearSelectedContract,
    setInitState,
    notifications,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}

export default useAppStore;
