import { ability } from "@/plugins/casl/AppAbility";
import { IMenu } from "./types";

const policies: IMenu = {
  header: "Полисы",
  children: [
    {
      title: "Новый полис",
      route: "home",
      resource: "Policies",
      permission: ability.POLICY_CREATE,
    },
    {
      title: 'Заявки "ИСЖ"',
      route: "contracts-invest",
      resource: "Policies",
      permission: ability.POLICY_READ,
    },
    {
      title: 'Заявки "НСЖ"',
      route: "contracts-nsg",
      resource: "Policies",
      permission: ability.POLICY_READ,
    },
    {
      title: 'Заявки "ФД"',
      route: "contracts-fixincome",
      resource: "Policies",
      permission: ability.POLICY_READ,
    },
    {
      title: "Заявки на согласовании",
      route: "contracts-underwriting",
      resource: "Policies",
      permission: ability.POLICY_READ,
    },
    {
      title: "Подтвержденные договоры",
      route: "contracts-confirmed",
      resource: "Policies",
      permission: ability.POLICY_READ,
    },
  ],
};
export default policies;
