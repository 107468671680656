import { ability } from "@/plugins/casl/AppAbility";
import { IMenu } from "./types";

const ContractSupport: IMenu = {
  header: "Сопровождение договоров",
  children: [
    {
      title: "Поиск договора",
      route: "contracts-support-search",
      resource: "ContractsSupport",
      permission: ability.CONTRACTS_SUPPORT,
    },
    {
      title: "Заявления на дожитие",
      route: "payoutClaim-list",
      resource: "ContractsSupport",
      permission: ability.PAYOUTCLAIM_READ,
    },
  ],
};

export default ContractSupport;
