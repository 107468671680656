import { ApiClient, headers_json } from "@/composable/useApi";

import { IChangePasswordBody } from "./types";

class AuthApiClient extends ApiClient {
  public async authLogin(login: string, password: string) {
    const formData = new FormData();
    formData.append("username", login);
    formData.append("password", password);

    const response = await this.axiosInstance.post(`${this.apiPath}/auth/login`, formData);
    return response.data;
  }

  public async authLogout() {
    const response = await this.axiosInstance.post(`${this.apiPath}/auth/logout`);
    return response.data;
  }

  public async authResetPassword(email: string) {
    await this.axiosInstance.get(`${this.apiPath}/auth/reset-password`, {
      params: { email },
    });
  }

  public async authChangePassword(data: IChangePasswordBody) {
    await this.axiosInstance.post(`${this.apiPath}/auth/change-password`, JSON.stringify(data), {
      headers: headers_json,
    });
  }

  public async getUserInfo() {
    const response = await this.axiosInstance.get(`${this.apiPath}/users/me`);
    return response.data;
  }
}
const authApiClient = new AuthApiClient();

export default authApiClient;
