import { ref, computed, watch } from "vue";
import { acceptHMRUpdate, defineStore } from "pinia";
import { useRouter } from "vue-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useStorage } from "@vueuse/core";
import { isAxiosError } from "axios";

import { loggedKey } from "@/config";
import notify from "@/composable/useNotify";
import { IHttpResponse } from "@/types/Api";
import apiClient from "./api";
import ability, { initialAbility } from "@/plugins/casl/ability";
import { IUser } from "./types";
import { useAppStore } from "@/modules/app";

const useAuthStore = defineStore("auth", () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const appStore = useAppStore();

  const isLogged = useStorage(loggedKey, false);
  const isLoggedIn = computed(() => String(isLogged.value).toLowerCase() === "true");
  const user = ref<IUser>({} as IUser);

  const userName = computed(() => user?.value.fullName ?? "");

  function setAuthUser(userData: IUser | undefined) {
    if (userData) {
      user.value = userData;
      ability.update(user.value.abilities);
      appStore.сontractRegistrationParams = userData.settings.сontractRegistrationParams;
      isLogged.value = true;
    } else {
      user.value = {} as IUser;
      ability.update(initialAbility);
      isLogged.value = false;
    }
  }

  // Load user info
  // Загрузка данных о пользователе является основной информацией,
  // поэтому выводим app layer (loading или error)
  const { status, data, error } = useQuery<IHttpResponse<IUser>>({
    queryKey: ["user"],
    queryFn: () => apiClient.getUserInfo(),
    enabled: isLoggedIn,
    retry: 1,
    meta: {
      enableAppErrorLayer: true,
      errorsTitle: "Данные пользователя",
      enableAutoLogout: true,
    },
  });
  watch(
    status,
    (newStatus) => {
      switch (newStatus) {
        case "pending":
          // Если это первоначальная загрузка, то отображаем загрузку
          // Иначе обновление проводится в фоне
          appStore.isLoading = !appStore.mainDataLoaded;
          break;
        case "success":
          setAuthUser(data.value?.data);
          appStore.mainDataLoaded = true;
          appStore.isLoading = false;
          break;
        case "error":
          appStore.isLoading = false;
          if (isAxiosError(error.value) && error.value.response?.status === 403) {
            logout();
          }
          break;
      }
    },
    { immediate: true },
  );

  // Login
  const { mutate: login, isPending: loginIsLoading } = useMutation({
    mutationFn: ({ login, password }: { login: string; password: string }) =>
      apiClient.authLogin(login, password),
    onSuccess: (data) => {
      setAuthUser(data.data);
      notify({ type: "success", text: `Вы вошли, как ${user.value.fullName}` });
      router.push({ name: "home" });
    },
    meta: {
      errorsTitle: "Ошибка входа",
    },
  });

  // Logout
  const { mutate: logout } = useMutation({
    mutationFn: () => apiClient.authLogout(),
    onSettled: () => {
      // Т.к. мидл может быть недоступен, то в любом случае сбрасываем на фронте настройки
      queryClient.clear();
      setAuthUser(undefined);
      router.push({ name: "auth-login" });

      // Сбрасываем данные для app layer
      appStore.setInitState();
    },
  });

  return { user, isLoggedIn, userName, login, loginIsLoading, logout };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}

export default useAuthStore;
