const routes = [
  {
    path: "/login",
    name: "auth-login",
    component: () => import("./views/AuthLogin.vue"),
    meta: {
      layout: "Full",
      requiresAuth: false,
    },
  },
  {
    path: "/user",
    name: "user-profile",
    component: () => import("./views/UserProfile.vue"),
    meta: {
      layout: "Horizontal",
      requiresAuth: true,
    },
  },
];

export default routes;
