<script setup lang="ts">
  import { ref, reactive, computed, watch, watchEffect } from "vue";

  import { useFieldContext } from "@/composable/useFieldContext";

  type TypeMultiseselectProps = {
    modelValue?: any;
    options: any;
    mode?: "single" | "multiple" | "tags";
    valueProp?: string;
    labelProp?: string;
    searchable?: boolean;
    canDeselect?: boolean;
    canClear?: boolean;
    isDisabled?: boolean;
    isInvalid?: boolean;
    returnObject?: boolean;
    asyncSearchable?: boolean;
    minCharsAsyncSearch?: number;
  };
  type TypeInputEmits = {
    (e: "update:modelValue", value: any): void;
  };

  const props = withDefaults(defineProps<TypeMultiseselectProps>(), {
    modelValue: "",
    mode: "single",
    valueProp: "id",
    labelProp: "label",
    searchable: false,
    canDeselect: false,
    canClear: true,
    isDisabled: false,
    isInvalid: false,
    returnObject: false,
    asyncSearchable: false,
    minCharsAsyncSearch: 3,
  });
  const emits = defineEmits<TypeInputEmits>();

  const fieldContext = reactive(
    useFieldContext({
      create: false,
      help: "BaseMultiselect",
    }),
  );

  const selectInput = ref();
  const val = ref(fieldContext.field?.value ?? props.modelValue);

  const hasError = computed(() =>
    fieldContext?.field ? Boolean(fieldContext?.field?.errorMessage) : false,
  );

  watch(val, () => {
    fieldContext.field?.handleBlur();
    fieldContext.field?.handleChange(val.value);
    emits("update:modelValue", val.value);
  });
  watchEffect(() => {
    val.value = fieldContext.field?.value ?? props.modelValue;
  });

  defineExpose({
    selectInput,
  });
</script>

<template>
  <MultiSelect
    :id="fieldContext.id"
    ref="selectInput"
    v-model="val"
    :options="props.options"
    :mode="props.mode"
    :value-prop="props.valueProp"
    :label="props.labelProp"
    :searchable="props.searchable || props.asyncSearchable"
    :can-deselect="props.canDeselect"
    :can-clear="props.canClear"
    :class="{ 'is-invalid': props.isInvalid || hasError }"
    :disabled="props.isDisabled"
    :object="props.returnObject"
    no-options-text="Нет данных"
    no-results-text="Не найдены значения"
    :delay="props.asyncSearchable ? 1000 : -1"
    :filter-results="props.asyncSearchable ? false : true"
    :resolve-on-load="false"
    :min-chars="props.minCharsAsyncSearch"
  />
</template>
