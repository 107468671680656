<script setup lang="ts">
  import { ref } from "vue";

  import availableMenu from "@/navigation";
  import NavigationDesktopMenu from "./navigation/NavigationDesktopMenu.vue";
  import NavigationMobileMenu from "./navigation/NavigationMobileMenu.vue";
  import AppSidebar from "./AppSidebar.vue";
  import { NavigationUserDropdown } from "@/modules/auth";
  import { NotificationsDropdown } from "@/modules/app";

  const mobileSidebarOpen = ref(false);
</script>

<template>
  <nav class="relative z-30 w-full shrink-0 bg-primary-700 py-1 text-sm text-gray-50 shadow-md">
    <div class="flex flex-wrap items-center justify-between px-2 sm:px-4">
      <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div class="absolute inset-y-0 left-0 flex items-center pl-2 sm:hidden">
          <font-icon icon="fa-solid fa-bars" @click="mobileSidebarOpen = !mobileSidebarOpen" />
        </div>

        <router-link to="/">
          <img class="py-1" src="@/assets/logo-white.png" />
        </router-link>

        <div class="ml-3 hidden space-x-1 sm:flex">
          <NavigationDesktopMenu :navigations="availableMenu" />
        </div>
      </div>
      <div class="flex items-center space-x-2">
        <NotificationsDropdown />
        <NavigationUserDropdown />
      </div>
    </div>
  </nav>
  <AppSidebar :is-open="mobileSidebarOpen" @close="mobileSidebarOpen = false">
    <NavigationMobileMenu :navigations="availableMenu" />
  </AppSidebar>
</template>
