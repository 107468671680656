import { App, defineAsyncComponent } from "vue";

export const regPlugin = (app: App) => {
  app.component(
    "MultiSelect",
    defineAsyncComponent({
      loader: () => import("@vueform/multiselect").then((mod) => mod.default),
      delay: 0,
      suspensible: false,
    }),
  );
};
