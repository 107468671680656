<script setup lang="ts">
  import { h, ref, computed, watch } from "vue";
  import {
    getCoreRowModel,
    useVueTable,
    createColumnHelper,
    getSortedRowModel,
    RowSelectionState,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedMinMaxValues,
    getFacetedUniqueValues,
    type OnChangeFn,
    type SortingState,
    type ColumnFiltersState,
    type Table,
    type ColumnDef,
    type Row,
  } from "@tanstack/vue-table";

  import { ITableColumn, ITableOptions, IColumnFilters } from "@/types/Table";
  import { createSignal } from "@/composable/useCreateSignal";

  import TablePagination from "./dataTable/TablePagination.vue";
  import TableColumns from "./dataTable/TableColumns.vue";
  import TableFilters from "./dataTable/TableFilters.vue";
  import TableRows from "./dataTable/TableRows.vue";
  import TableFooter from "./dataTable/TableFooter.vue";
  import TableCheckbox from "./dataTable/TableCheckbox.vue";

  type TypeTableProps = {
    columns: ITableColumn[];
    data: object[];
    totalItems?: number;
    isLoading?: boolean;
    readonly?: false;
    enablePagination?: boolean;
    pageSizes?: number[];
    pageSize?: number;
    enableColumnFilters?: boolean;
    enableColumnSorting?: boolean;

    isRowSelectable?: boolean;
    rowKey?: string;
    selectedRow?: string | number;

    addColumnSelect?: boolean;
    addRowNumber?: boolean;

    manualSorting?: boolean;
    fixedHeight?: boolean;

    showExportExcel?: boolean;
    exportExcelIsLoading?: boolean;
  };

  type TypeTableEmits = {
    (e: "reloadTable"): void;
    (e: "updatedTableOptions", options: ITableOptions): void;
    (e: "exportItems", formatType: string): void;
    (e: "selectRow", row: any, idx: number): void;
    (e: "doubleClickRow", row: any, idx: number): void;
  };

  const props = withDefaults(defineProps<TypeTableProps>(), {
    totalItems: 0,
    isLoading: false,
    readonly: false,
    enablePagination: false,
    pageSizes: () => [50, 100, 200, 400],
    pageSize: 50,
    enableColumnFilters: false,
    enableColumnSorting: false,

    isRowSelectable: false,
    rowKey: "id",
    selectedRow: undefined,

    addColumnSelect: false,
    addRowNumber: false,

    manualSorting: true,
    fixedHeight: false,

    showExportExcel: true,
    exportExcelIsLoading: false,
  });
  const emits = defineEmits<TypeTableEmits>();

  defineSlots<{
    filterAdditionalInfo(): any;
  }>();

  const columnHelper = createColumnHelper<any>();

  const createSelectColumn = () => {
    return columnHelper.display({
      id: "select",
      header: ({ table }: { table: Table<any> }) => {
        const state = table.getIsAllPageRowsSelected()
          ? "checked"
          : table.getIsSomePageRowsSelected()
          ? "indeterminate"
          : "unchecked";
        return h(TableCheckbox, {
          state,
          onChange: table.getToggleAllPageRowsSelectedHandler(),
        });
      },
      cell: ({ row }: { row: Row<any> }) => {
        const state = row.getIsSelected() ? "checked" : "unchecked";
        return h(TableCheckbox, {
          state,
          onChange: row.getToggleSelectedHandler(),
        });
      },
    });
  };

  const createRowNumberColumn = () => {
    return columnHelper.display({
      id: "rowNumber",
      cell: ({ row }: { row: Row<any> }) => {
        return h("span", row.index + 1);
      },
    });
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const columns = computed(() => {
    const newColumns: ColumnDef<any, any>[] = [];

    if (props.addColumnSelect) {
      newColumns.push(createSelectColumn());
    }

    if (props.addRowNumber) {
      newColumns.push(createRowNumberColumn());
    }

    props.columns.map((item) => {
      const newColumn = columnHelper.accessor(item.id, {
        header: item.header,
        enableSorting: item.sorting !== undefined ? item.sorting : props.enableColumnSorting,
        enableColumnFilter:
          item.filtering !== undefined ? item.filtering : props.enableColumnFilters,
        cell: (cellProps) =>
          item.formatter ? item.formatter(cellProps.getValue()) : cellProps.getValue(),
        meta: {
          filterOptions: {
            inputType: item.filterOptions?.inputType ? item.filterOptions?.inputType : "input",
            valueType: item.filterOptions?.valueType ? item.filterOptions?.valueType : "string",
            selectOptions: item.filterOptions?.selectOptions
              ? item.filterOptions?.selectOptions
              : [],
            selectType: item.filterOptions?.selectType,
          },
        },
      });

      // FIXME: Разобраться. Но из-за этого работает min-max без доп. костылей
      if (item.filterOptions?.inputType === "range") newColumn.filterFn = undefined;

      newColumns.push(newColumn);
    });

    return newColumns;
  });

  const [rowSelection, setRowSelection] = createSignal<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = createSignal<IColumnFilters[]>([]);
  const sorting = ref<SortingState>();

  const data = ref(props.data);
  const rerenderTable = () => {
    data.value = [...props.data];
  };
  watch(
    () => props.data,
    () => {
      rerenderTable();
    },
    { immediate: true, deep: true },
  );

  const table = useVueTable({
    get data() {
      return data.value;
    },
    columns: columns.value,
    state: {
      get sorting() {
        return sorting.value;
      },
      get rowSelection() {
        return rowSelection();
      },
      get columnFilters() {
        return columnFilters();
      },
    },
    onSortingChange: (updaterOrValue: any) => {
      sorting.value =
        typeof updaterOrValue === "function" ? updaterOrValue(sorting.value) : updaterOrValue;
    },
    getCoreRowModel: getCoreRowModel(),
    manualSorting: props.manualSorting,
    getSortedRowModel: getSortedRowModel(),
    sortDescFirst: false,

    manualPagination: true,
    get rowCount() {
      return props.totalItems;
    },

    enableRowSelection: props.addColumnSelect,
    onRowSelectionChange: setRowSelection as OnChangeFn<RowSelectionState>,

    manualFiltering: true,
    enableColumnFilters: props.enableColumnFilters,
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),

    enableHiding: true,

    initialState: {
      pagination: {
        pageSize: props.pageSize,
      },
    },
  });

  const filtersApply = ref(false);
  const createTableOptions = (): ITableOptions => {
    const { pagination, columnFilters } = table.getState();

    const tableOptions: ITableOptions = {
      pagination: pagination,
    };
    if (sorting.value) tableOptions.sorting = sorting.value;
    if (filtersApply.value) tableOptions.filters = columnFilters as IColumnFilters[];

    return tableOptions;
  };

  const onRowClick = (row: Row<any>) => {
    emits("selectRow", row.original, row.index);
  };

  const onRowDoubleClick = (row: Row<any>) => {
    emits("doubleClickRow", row.original, row.index);
  };

  const onUpdatedFilters = (applyFilters: boolean) => {
    filtersApply.value = applyFilters;
    table.resetPageIndex();
  };

  watch([() => table.getState().pagination, sorting], () => {
    emits("updatedTableOptions", createTableOptions());
  });

  const setColumnVisibility = (columnName: string, value: boolean) => {
    table.setColumnVisibility((prev) => {
      return {
        ...prev,
        [columnName]: value,
      };
    });
  };
  defineExpose({ setColumnVisibility });
</script>

<template>
  <div class="relative flex h-full w-full flex-col" :class="[props.isLoading && 'overflow-hidden']">
    <TablePagination
      v-if="props.enablePagination"
      :table="table"
      :page-sizes="props.pageSizes"
      :total-items="props.totalItems"
      :show-export-excel="props.showExportExcel"
      :export-excel-is-loading="props.exportExcelIsLoading"
      @reload-table="emits('reloadTable')"
      @export-items="(formatType) => emits('exportItems', formatType)"
    />
    <div class="grow overflow-x-auto">
      <div :class="[props.fixedHeight && 'max-h-72 overflow-auto']">
        <table class="relative w-full whitespace-nowrap">
          <thead class="sticky top-0 bg-gray-200">
            <TableColumns :header-groups="table.getHeaderGroups()" />
            <template v-if="table.options.enableColumnFilters">
              <TableFilters
                :table="table"
                :total-columns="columns.length"
                @filters-updated="onUpdatedFilters"
              >
                <template #additionalInfo>
                  <slot name="filterAdditionalInfo"></slot>
                </template>
              </TableFilters>
            </template>
          </thead>
          <tbody class="divide-y divide-gray-200 overflow-y-auto bg-white">
            <template v-if="table.getRowModel().rows.length">
              <TableRows
                :rows="table.getRowModel().rows"
                :is-row-selectable="props.isRowSelectable"
                :readonly="props.readonly"
                :id-key="props.rowKey"
                :selected-row-id="selectedRow"
                @select-row="onRowClick"
                @double-click-row="onRowDoubleClick"
              />
            </template>
            <template v-else>
              <tr>
                <td :colspan="columns.length" class="py-1 text-center font-medium">
                  <span>Данные отсутствуют</span>
                </td>
              </tr>
            </template>
          </tbody>
          <TableFooter :footer-groups="table.getFooterGroups()" />
        </table>
      </div>
    </div>
    <el-spinner :active="props.isLoading" />
  </div>
</template>
