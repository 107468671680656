<script setup lang="ts">
  import { reactive, computed } from "vue";

  import { TypeFieldLabelSize } from "@/types/Field";
  import { useFieldContext } from "@/composable/useFieldContext";

  import FieldHorizontalLabel from "./field/FieldHorizontalLabel.vue";
  import FieldTooltip from "./field/FieldTooltip.vue";

  type TypeFieldProps = {
    id?: string;
    subcontrol?: boolean;
    label?: string;
    required?: boolean;
    requiredFn?: (fieldName: string) => boolean;
    horizontal?: boolean;
    labelSize?: TypeFieldLabelSize;
    errorMsg?: string;
    description?: string;
    tooltip?: string;
  };

  const props = withDefaults(defineProps<TypeFieldProps>(), {
    id: undefined,
    subcontrol: undefined,
    label: undefined,
    labelSize: undefined,
    errorMsg: undefined,
    description: undefined,
    required: undefined,
    requiredFn: () => false,
    tooltip: undefined,
  });

  const fieldContext = reactive(useFieldContext({ id: props.id, inherit: !props.subcontrol }));

  const required = computed(() => {
    // Если значение не установлено вручную, то смотрим на функцию
    if (props.required !== undefined) return props.required;
    return fieldContext.id ? props.requiredFn(fieldContext.id as string) : false;
  });

  const labelClasses = computed(() => [required.value && "is-required"]);
  const errorMessage = computed(() => props.errorMsg || fieldContext?.field?.errorMessage);
  const hasAdditionalInfo = computed(() => props.description || errorMessage.value);

  defineExpose(fieldContext);
</script>

<template>
  <template v-if="props.horizontal">
    <div
      v-bind="$attrs"
      class="flex flex-col items-center last:pb-0 sm:flex-row sm:last:pb-2"
      :class="[hasAdditionalInfo ? 'pb-0.5' : 'pb-2']"
    >
      <FieldHorizontalLabel :label-size="props.labelSize">
        <label :for="fieldContext.id" class="break-words" :class="labelClasses">
          {{ props.label }}
        </label>
        <FieldTooltip v-if="props.tooltip" :tooltip="props.tooltip" class="pl-1" />
      </FieldHorizontalLabel>
      <div class="w-full flex-1">
        <slot v-bind="fieldContext"></slot>
      </div>
    </div>
    <template v-if="hasAdditionalInfo">
      <div
        v-bind="$attrs"
        class="flex flex-col items-center pb-2 last:pb-0 sm:flex-row sm:last:pb-2"
      >
        <FieldHorizontalLabel :label-size="props.labelSize" class="hidden sm:flex" />
        <div class="w-full flex-1">
          <template v-if="props.description">
            <p class="text-help w-full">{{ props.description }}</p>
          </template>
          <template v-if="errorMessage">
            <p class="text-help w-full text-danger-500">{{ errorMessage }}</p>
          </template>
        </div>
      </div>
    </template>
  </template>
  <template v-else>
    <div v-bind="$attrs" class="flex flex-col gap-1 pb-2">
      <label :for="fieldContext.id" class="break-words" :class="labelClasses">
        {{ props.label }}
      </label>
      <FieldTooltip v-if="props.tooltip" :tooltip="props.tooltip" />
      <slot v-bind="fieldContext"></slot>
      <template v-if="props.description">
        <p class="text-help">{{ props.description }}</p>
      </template>
      <template v-if="errorMessage">
        <p class="text-help text-danger-500">{{ errorMessage }}</p>
      </template>
    </div>
  </template>
</template>
