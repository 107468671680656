import { MongoAbility, createMongoAbility } from "@casl/ability";

export type Actions = "create" | "read" | "report" | "manage";

export type Subjects = "Policy" | "all";

export type AppAbility = MongoAbility<[Actions, Subjects]>;

export interface IUserAbility {
  action: Actions;
  subject: Subjects;
}

export const AppAbility = createMongoAbility<AppAbility>();

export const ability = Object.freeze({
  ADMIN: { action: "manage", subject: "all" },
  STAFF_READ_ONLY: { action: "read", subject: "all" },

  POLICY_CREATE: { action: "create", subject: "Policy" },
  POLICY_READ: { action: "read", subject: "Policy" },
  CAN_CHANGE_RESPONSIBLE: { action: "changeResponsible", subject: "Policy" },

  CONTRACTS_SUPPORT: { action: "create", subject: "ContractsSupport" },

  PAYOUTCLAIM_CREATE: { action: "create", subject: "PayoutClaim" },
  PAYOUTCLAIM_READ: { action: "read", subject: "PayoutClaim" },
});
