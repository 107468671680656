import { ProductGroupType } from "./models/Product";

const routes = [
  {
    path: "/contract",
    children: [
      {
        path: ProductGroupType.INVEST,
        name: `contract-${ProductGroupType.INVEST}`,
        component: () => import("./views/ContractInvest.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
      {
        path: ProductGroupType.FIX_INCOME,
        name: `contract-${ProductGroupType.FIX_INCOME}`,
        component: () => import("./views/ContractFixIncome.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
      {
        path: ProductGroupType.NSG,
        name: `contract-${ProductGroupType.NSG}`,
        component: () => import("./views/ContractNsg.vue"),
        meta: {
          layout: "Horizontal",
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
